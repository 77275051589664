import React, { Component } from 'react';

class Footer extends Component {
  render() {

    if (this.props.data) {
      var networks = this.props.data.social.map(function (network) {
        return <li key={network.name}><a href={network.url} target="_blank" rel="noopener noreferrer"><i className={network.className}></i></a></li>
      })
    }

    return (
      <footer>

        <div className="row">
          <div className="twelve columns">
            <ul className="social-links">
              {networks} </ul>
            <ul className="social-links">
              <a href="https://wa.me/+918762615502/?text=Hi%20Akshay,%20I%20am%20interested%20to%20learn%20yoga%20from%20you,%20please%20let%20me%20know%20the%20next%20steps!%20Thank%20You." target="_blank" rel="noopener noreferrer"><i className="fa fa-phone"></i></a>
            </ul>

            <ul className="copyright">
              <li>Developed and maintained by &copy; hightechguru.net</li>
            </ul>

          </div>
          <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
