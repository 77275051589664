import React, { Component } from 'react';
import PictureFlow from './PictureFlow';
class AdditionalDetails extends Component {
   render() {

      return (
         <section id="additional" style={{ backgroundColor: 'lightgreen' }}>
               < div align="center">
                  <br/>
                  <h4 style={{ color: "white", padding: "30px" }}>Some pictures from my Yoga practice!</h4>
                  <PictureFlow />
                  <br/>
               </div>
            


         </section>
      );
   }
}

export default AdditionalDetails;
