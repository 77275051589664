import React, { Component } from 'react';

class Contact extends Component {
   render() {
      if (this.props.data) {
         var name = this.props.data.name;
         //var profilepic = "images/" + this.props.data.image;
         var city = this.props.data.address.city;
         var state = this.props.data.address.state;
         var zip = this.props.data.address.zip;
         var phone = this.props.data.phone;
         var email = this.props.data.email;
      }
      return (
         <section id="contact">
            < div align="center">
               <h3>Contact Details</h3>
            </div>
            <div align="center">
               <h4 className="address">
                  <span>{name}</span><br />
                  <span>
                     {city} {state}, {zip}
                  </span><br />
                  <span>{phone}</span><br />
                  <span>{email}</span><br />
               </h4>
               <h2><a href="https://wa.me/+918762615502/?text=Hi%20Akshay,%20I%20am%20interested%20to%20learn%20yoga%20from%20you,%20please%20let%20me%20know%20the%20next%20steps!%20Thank%20You." target="_blank" rel="noopener noreferrer"><i className="fa fa-phone"></i></a>
               </h2>
               <h4>Contact me via WhatsApp or any other social media links given below!</h4>

            </div>
         </section>
      );
   }
}

export default Contact;
