import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
class PictureFlow extends React.Component {

  render() {

    const images = [
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic1.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic1.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic2.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic2.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic3.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic3.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic4.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic4.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic5.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic5.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic6.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic6.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic7.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic7.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic8.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic8.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic9.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic9.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic10.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic10.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic11.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic11.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic12.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic12.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic13.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic13.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic14.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic14.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic15.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic15.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic16.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic16.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic17.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic17.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic18.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic18.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic19.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic19.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic20.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic20.jpg',

      },
      {
        original: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic21.jpg',
        thumbnail: 'https://guruweb-image-folder.s3.amazonaws.com/akshay_data/pic21.jpg',

      },
    ]

    return (
      <section>
          <div className="row nine">
            <ImageGallery items={images} autoPlay={true} thumbnailPosition='top' />
          </div>
      </section>
    );
  }

}
export default PictureFlow;

