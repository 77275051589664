   import React from 'react';
   import ReactTextRotator from 'react-text-rotator';
    
   const content = [
     {
       text: 'Ahimsa (Peace).',
       className: 'classA',
       animation: 'fade',
     },
     {
       text: 'Satya (Truthfulness).',
       className: 'classB',
       animation: 'fade',
     },
     {
       text: 'Asteya (Honesty).',
       className: 'classC',
       animation: 'fade',
     },
     {
       text: 'Brahmacharya (Continence).',
       className: 'classD',
       animation: 'fade',
     },
     {
       text: 'Aparigraha (Non-possessiveness).',
       className: 'classE',
       animation: 'fade',
     },
     {
      text: 'Shaucha (Purity).',
      className: 'classE',
      animation: 'fade',
    },{
      text: 'Santosha (Contentment).',
      className: 'classE',
      animation: 'fade',
    },{
      text: 'Tapah (Austerities).',
      className: 'classE',
      animation: 'fade',
    },{
      text: 'Swadyaya (Self-study).',
      className: 'classE',
      animation: 'fade',
    },{
      text: 'Ishwara pranidhana (Faith).',
      className: 'classE',
      animation: 'fade',
    },
   ];
    
   const LeadershipPrinciples = () => (
     <div align="center">
       <br/>
       <h3>The Ten Principles of Yoga. </h3>
       <h3><ReactTextRotator
         content={content}
         time={5000}
         startDelay={2000}
       /></h3><br/>
     </div>
   );

export default LeadershipPrinciples;
